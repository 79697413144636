<!--banner图-->
<template>
	<div class="uploadBox flex flex-wrap">
		<div v-for="(item,index) in imageList" :key="index" class="preImageBox">
			<vue-hover-mask>
				<!-- 默认插槽 -->
				<el-image :src="domain +  item.url" fit="cover" :style="{width:imageWidth,height:imageHeight}"></el-image>
				<template v-slot:action>
					<i class="el-icon-zoom-in" :style="{marginRight:(!mayEdit ? '10px' : '')}" @click="handleFilePreview(index)"></i>
					<i v-if="!mayEdit" class="el-icon-delete-solid" @click="handleFileRemove(index)"></i>
				</template>
			</vue-hover-mask>
		</div>
		
		<el-upload
			v-if="imageList.length < limitNum && !mayEdit"
			:action="imgurl()" 
			:style="{width:width,height:height}"
			name="pic" 
			:limit="limitNum" 
			ref="uploadFile" 
			list-type="picture-card"
			:show-file-list="false"
			:with-credentials="true"
			:on-success="handleFileSuccess" 
			:on-exceed="handleFileExceed">
			<i class="el-icon-camera-solid"></i>
		</el-upload>
		
		<el-image-viewer v-if="dialogImageVisible" :on-close="closeViewer" :url-list="[previewImageUrl]"/>
	</div>
</template>
<script>
	import VueHoverMask from 'vue-hover-mask';
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
	export default {
		data() {
			return {
				credentials: true,
				dialogImageVisible:false,
				
				previewImageUrl:[],
			}
		},
		props: [
			'imageList',
			'clickFile',
			'limitNum',
			'width',
			'height',
			'imageWidth',
			'imageHeight',
			'mayEdit'
		],
		components: {
			VueHoverMask,
			ElImageViewer
		},
		methods: {
			//获取接口地址
			imgurl() {
				return this.domain + "/upload/upFile"; //接口地址
			},
			
			//图片上传成功的函数
			handleFileSuccess(response) {
				if (parseInt(response.code) != 200) return this.$message({
					type: 'warning',
					message: '上传失败，请重试'
				})
				
				this.imageList.push({
					url:response.data.path
				});
				this.$emit("upload", this.imageList,this.clickFile);
			},

			//移除文件时的钩子函数
			handleFileRemove(index) {
				this.imageList.splice(index, 1);
				this.$emit("upload", this.imageList,this.clickFile);
			},

			// 预览图片
			handleFilePreview(index) {
				//显示预览
				this.dialogImageVisible = true;
				//图片预览路径
				this.previewImageUrl = this.domain + this.imageList[index].url;
			},
			
			// 关闭查看器
			closeViewer() {
				this.dialogImageVisible = false
			},
			//文件上传超出
			handleFileExceed(files, fileList) {
				if (this.limitNum == fileList.length) {
					this.$message.error(`只允许上传${this.limitNum}张图片！`);
				}
			}
		}
	}
</script>
<style lang="less">
	.preImageBox{
		margin-right: 6px;
		border: 1px solid #e9e9e9;
		border-radius: 6px;
		margin-bottom: 6px;
	}
	.uploadBox{
		.el-upload{
			width: 100%;
			height: 100%;
			.el-icon-camera-solid{
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			}
		}
	}
</style>
